import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import IdleTimer from "./IdleTimer";
import Routes from "./router/Routes";
import "./App.css";

const App = () => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 900, //expire after 15 minutes of inactivity
      onTimeout: () => {
        handleLogout();
      },
      onExpired: () => {
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
