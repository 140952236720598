import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import RedirectionRoutes from "./RedirectionRoutes";
import Loader from "react-loader-spinner";
const Dashboard = React.lazy(() => import("../containers/Dashboard/Dashboard"));
const Profile = React.lazy(() => import("../components/Profile/Profile"));
const Offers = React.lazy(() => import("../containers/Offers/Offers"));
const Transactions = React.lazy(() =>
  import("../containers/Transactions/Transactions")
);
const TransactionDetails = React.lazy(() =>
  import("../containers/Transactions/TransactionDetails")
);
const PaymentDetail = React.lazy(() =>
  import("../containers/Transactions/PaymentDetail")
);
const AccumTrans = React.lazy(() =>
  import("../containers/Transactions/AccumTrans")
);
const EmiStatus = React.lazy(() =>
  import("../containers/Transactions/EmiStatus")
);
const Settings = React.lazy(() => import("../containers/Settings/Settings"));
const ApplyCredit = React.lazy(() =>
  import("../containers/ApplyCredit/ApplyCredit")
);
const Authentication = React.lazy(() =>
  import("../containers/Authentication/Authentication")
);
const LandingContainer = React.lazy(() =>
  import("../containers/Landing/LandingContainer")
);
const QrScanner = React.lazy(() => import("../containers/QrScanner/QrScanner"));
const QrPaymentTerms = React.lazy(() => import("../containers/QrScanner/QrPaymentTerms"));
const QrPaymentOptions = React.lazy(() => import("../containers/QrScanner/QrPaymentOptions"));
const MyAccount = React.lazy(() => import("../containers/MyAccount/MyAccount"));
const DocsUpload = React.lazy(() =>
  import("../components/DocsUpload/DocsUpload")
);
const BankDetails = React.lazy(() =>
  import("../components/BankDetails/BankDetails")
);
const HelpFaqs = React.lazy(() =>
  import("../components/HelpFaqs/HelpFaqs")
);
const ViewDocs = React.lazy(() =>
  import("../components/ViewDocs/ViewDocs")
);
const IbvTransactions = React.lazy(() =>
  import("../components/IbvTransactions/IbvTransactions")
);
const PaymentTerms = React.lazy(() =>
  import("../components/IbvTransactions/PaymentTerms")
);
const PaymentOptions = React.lazy(() =>
  import("../components/IbvTransactions/PaymentOptions")
);
const PaymentConfirmation = React.lazy(() =>
  import("../components/IbvTransactions/PaymentConfirmation")
);

const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <Loader
              type="BallTriangle"
              color="#00BFFF"
              height={40}
              width={40}
            />
          </div>
        }
      >
        <Switch>
          <ProtectedRoutes exact path="/dashboard" component={Dashboard} />
          <ProtectedRoutes exact path="/profile" component={Profile} />
          <ProtectedRoutes exact path="/offers" component={Offers} />
          <ProtectedRoutes
            exact
            path="/transactions"
            component={Transactions}
          />
          <ProtectedRoutes exact path="/settings" component={Settings} />
          <ProtectedRoutes exact path="/paymentterms" component={PaymentTerms} />
          <ProtectedRoutes exact path="/paymentoptions" component={PaymentOptions} />
          <ProtectedRoutes exact path="/transactiondetails" component={TransactionDetails} />
          <ProtectedRoutes exact path="/paymentdetail" component={PaymentDetail} />
          <ProtectedRoutes exact path="/paymentconfirmation" component={PaymentConfirmation} />
          <ProtectedRoutes exact path="/accumtrans" component={AccumTrans} />
          <ProtectedRoutes exact path="/emistatus" component={EmiStatus} />
          <ProtectedRoutes exact path="/applyCredit" component={ApplyCredit} />
          <ProtectedRoutes exact path="/qrscanner" component={QrScanner} />
          <ProtectedRoutes exact path="/qrpaymentterms" component={QrPaymentTerms} />
          <ProtectedRoutes exact path="/qrpaymentoptions" component={QrPaymentOptions} />
          <ProtectedRoutes exact path="/myaccount" component={MyAccount} />
          <ProtectedRoutes exact path="/docsupload" component={DocsUpload} />
          <ProtectedRoutes exact path="/bankdetails" component={BankDetails} />
          <ProtectedRoutes exact path="/helpfaqs" component={HelpFaqs} />
          <ProtectedRoutes exact path="/viewdocs" component={ViewDocs} />
          <ProtectedRoutes exact path="/ibvtransactions/:bankName" component={IbvTransactions} />
          <RedirectionRoutes
            exact
            path="/authentication/:loginType"
            component={Authentication}
          />
          <Route exact path="/" component={LandingContainer} />
        </Switch>
      </Suspense>
    </Router>
  );
};
export default Routes;
